<template>
  <div id="login">
    <van-field
      v-model="account"
      label="手机号"
      placeholder="请输入手机号"
      left-icon="phone-o"
      autocomplete="off"
      clearable
      type="number"
      label-width="5.2em"
    />
    <van-field
      v-model="password"
      label="密码"
      placeholder="请输入密码(大于6位)"
      left-icon="smile-o"
      type="password"
      autocomplete="off"
      clearable
      label-width="5.2em"
    />
    <van-button round type="info" block @click="login">登录</van-button>
  </div>
</template>

<script>
import { userlogin, openIdLogin } from "@/api/mine/mine";
import { Toast } from "vant";
import { isPhone, isPassword } from "@/utils/validate";
export default {
  data() {
    return {
      account: "", //账户
      password: "", //密码
      wxcode: "",
      wxstate: "",
    };
  },
  created() {
    const { code, state } = this.getUrlParams();
    if (code && state) {
      this.wxcode = code;
      this.wxstate = JSON.parse(decodeURIComponent(state));
      this.openIdLogin();
    }
  },
  methods: {
    // 【转换】获取路径"?"后面的参数
    getUrlParams() {
      let url = location.search;
      let params = new Object();
      if (url.indexOf("?") != -1) {
        url = url.slice(1).split("&");
        for (let i of url) {
          params[i.split("=")[0]] = decodeURI(i.split("=")[1]);
        }
      }
      return params;
    },

    // 授权登录
    openIdLogin() {
      const { codeType, path, query } = this.wxstate;
      let data = {
        code: this.wxcode,
        codeType,
        spId: query.spId,
      };
      openIdLogin(data).then((res) => {
        if (res.success) {
          window.localStorage.setItem("accessToken", res.data.accessToken);
          // 企业微信登录
          if (codeType == 1) {
            this.$router.push({
              path,
              query,
            });
          }
          // 微信公众号登录
          else if (codeType == 2) {
            this.$router.push({
              path: "/home/homeInfo",
            });
          }
        }
      });
    },

    // 【监听】登录
    login() {
      if (!isPhone(this.account)) {
        return Toast({
          message: "请输入正确的手机号",
          position: "top",
        });
      }
      if (!isPassword(this.password)) {
        return Toast({
          message: "密码必须大于6位",
          position: "top",
        });
      }
      let data = {
        account: this.account,
        password: this.password,
      };
      userlogin(data).then((res) => {
        if (res.success) {
          //存用户信息和Token
          window.localStorage.setItem("accessToken", res.data.accessToken);
          window.localStorage.setItem("userInfo", JSON.stringify(res.data));
          this.$router.push({
            path: "/home/homeInfo",
          });
        } else {
          Toast({
            message: res.msg,
            position: "top",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#login {
  width: 3rem;
  height: 2rem;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
</style>